.home-content {
  --background: #ffffff url("../../assets/top-sample1.png") no-repeat center
    center / cover;
}

.sampleA {
  width: 300px;
  height: 300px;
  background: conic-gradient(
    #ecd251 120deg,
    #6dae4c 120deg 240deg,
    #5799e6 240deg
  );
  border-radius: 50%;
}

.button-position {
  display: flex;
  align-items: center;
  justify-content: center;
}

.answerA {
  background-color: #5799e6;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 10%;
}

.answerB {
  background-color: #ecd251;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 15%;
}

.answerC {
  background-color: #6dae4c;
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.space-between {
  height: 50px;
}

@-moz-document url-prefix() {
  /* ここに書く */
  .answerB {
    background-color: #ecd251;
    color: white;
    font-weight: bold;
    font-size: 12px;
    border-radius: 15%;
  }

  .answerC {
    background-color: #6dae4c;
    color: white;
    font-weight: bold;
    font-size: 12px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* ここに書く */
  .answerB {
    background-color: #ecd251;
    color: white;
    font-weight: bold;
    font-size: 12px;
    border-radius: 15%;
  }

  .answerC {
    background-color: #6dae4c;
    color: white;
    font-weight: bold;
    font-size: 12px;
  }
}

.Android .answerB {
  background-color: #ecd251;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 15%;
}

.Android .answerC {
  background-color: #6dae4c;
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.height-branks {
  height: 30px;
}

.background-image {
  --background: #ffffff url('../../assets/top-image.png') no-repeat center center / contain;
}


.login-text {
  font-size: 24px;
  font-weight: bold;
}

.login-button {
  position: relative;
  top: 81%;
}

.admin-login {
  padding-left: 45%;
}
.home-content {
  --background: #ffffff url('../../assets/top.png') no-repeat center center / cover;
}

.color-red {
  color: red;
}

.bottom-url {
  position: absolute;
  left: 5%;
  bottom: 10%;
}

.space-new {
  width: 100%;
  height: 350px;
}
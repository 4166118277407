.ripple-parent {
  position: relative;
  overflow: hidden;
}
.answer-type-B {
  color: #67A8F3;
}
.answer-type-A {
  color: #F1D252;
}
.answer-type-C {
  color: #83BC54;
}
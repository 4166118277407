.swiper-slide {
  display: block;
}

.slide-title {
  margin-top: 4.8rem;
}

.slide-image {
  max-height: 50%;
  max-width: 60%;
  margin: 36px 0;
  pointer-events: none;
}

b {
  font-weight: 500;
  color: var(--ion-text-color, #000000);
}

.explanation-slide {
  text-align: center;
}
.home {
  --background: #ffffff no-repeat center center / cover;
}
.title {
  font-size: 25px;
  color: white;
}
.resultTop {
  color: white;
}
.resultA {
  color: #62abd8;
  font-size: 20px;
}
.resultB {
  color: #8ab352;
  font-size: 20px;
}
.resultC {
  color: #d37d7b;
  font-size: 20px;
}
.resultD {
  color: rgb(204, 187, 93);
  font-size: 20px;
}
.no-1 {
  font-size: 35px;
}
.desire-to-dominate {
  color: red;
}
.radar {
  transform: rotate(45deg);
  left: 0px;
  top: -223px;
}
.recharts-surface {
  width: 600px;
  height: 600px;
}
.score50 {
  top: 67px;
  left: 90px;
  transform: rotate(315deg);
  position: absolute;
  z-index: 5;
  font-size: 20px;
  color: #adadad;
}
.score25 {
  top: 119px;
  left: 145px;
  transform: rotate(315deg);
  position: absolute;
  z-index: 5;
  font-size: 20px;
  color: #adadad;
}

.circleSample {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 40px;
  background: conic-gradient(
    #fffd54 90deg,
    #f19e9c 90deg 180deg,
    #a0cd63 180deg 270deg,
    #7fcbfa 270deg
  );
  border-radius: 50%;
  border: solid 1px #7a7a7a;
  z-index: 1;
}
.circleSample2 {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 150px;
  border: solid 1px #7a7a7a;
  border-radius: 50%;
  z-index: 4;
}
.circleSample3 {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 110px;
  border: solid 1px #7a7a7a;
  border-radius: 50%;
  z-index: 3;
}
.circleSample4 {
  width: 225px;
  height: 225px;
  position: absolute;
  top: 75px;
  border: solid 1px #7a7a7a;
  border-radius: 50%;
  z-index: 2;
}
.circleSample5 {
  position: absolute;
  top: 40px;
  z-index: 5;
}
.energyA {
  width: 145px;
  height: 33px;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: 0px;
  left: 2px;
  background-color: #3fa1df;
  font-size: x-large;
  border-radius: 20px;
  text-align: center;
}
.energyB {
  width: 145px;
  height: 33px;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: 345px;
  left: 2px;
  background-color: #7db42f;
  font-size: x-large;
  border-radius: 20px;
  text-align: center;
}
.energyC {
  width: 145px;
  height: 33px;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: 345px;
  right: 2px;
  background-color: #d3615f;
  font-size: x-large;
  border-radius: 20px;
  text-align: center;
}
.energyD {
  width: 145px;
  height: 33px;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: 0px;
  right: 2px;
  background-color: gold;
  font-size: x-large;
  border-radius: 20px;
  text-align: center;
}
.energyA1 {
  width: 80px;
  height: 25px;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: 40px;
  left: 5px;
  background-color: #3fa1df;
  border-radius: 20px;
  text-align: center;
}
.energyB1 {
  width: 80px;
  height: 25px;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: 315px;
  left: 5px;
  background-color: #7db42f;
  border-radius: 20px;
  text-align: center;
}
.energyC1 {
  width: 80px;
  height: 25px;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: 315px;
  right: 5px;
  background-color: #d3615f;
  border-radius: 20px;
  text-align: center;
}
.energyD1 {
  width: 80px;
  height: 25px;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: 40px;
  right: 5px;
  background-color: gold;
  border-radius: 20px;
  text-align: center;
}

.radar-position {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 60%;
}

.verticalLine {
  width: 1px;
  height: 300px;
  background-color: #7a7a7a;
  z-index: 5;
  position: absolute;
  top: 40px;
}

.horizontalLine {
  width: 1px;
  height: 300px;
  background-color: #7a7a7a;
  transform: rotate(90deg);
  z-index: 5;
  position: absolute;
  top: 40px;
}

.diagonal-45Line {
  width: 1px;
  height: 300px;
  background-color: #7a7a7a;
  transform: rotate(45deg);
  z-index: 5;
  position: absolute;
  top: 40px;
}

.diagonal-135Line {
  width: 1px;
  height: 300px;
  background-color: #7a7a7a;
  transform: rotate(135deg);
  z-index: 5;
  position: absolute;
  top: 40px;
}

.font {
  font-size: 20px;
}

.cardTitle {
  font-size: x-large;
}

.result-card {
  --background: #000000;
  z-index: 10;
}

.result-card1 {
  --background: #ffffff;
}

.result-sort {
  display: block;
  margin-left: 11%;
}

.background-image-login {
  --background: #ffffff url("../../assets/sample-image.png") no-repeat center
    center / contain;
}

.login-card {
  --background: #cbcbd7;
  margin-top: 50%;
}

.input-item {
  border-radius: 20px;
  margin: 10px;
  height: 60px;
}

.input-value {
  height: 60px;
  color: #b5b0d4;
}

.fancy-button {
  float: right;
  --border-radius: 20px;
}

.instructions {
  float: right;
  color: white;
  font-weight: bold;
}

.blank-space {
  height: 200px;
}

.card-blank-space {
  height: 30px;
}

.card-blank-space-10px {
  height: 10px;
}

.error-message {
  color: red;
}

.admin-login-card {
  --background: #cbcbd7;
  margin-top: 20%;
}

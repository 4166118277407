.side-bar {
  width: 60px;
  background-color: white;
  height: 100vh;
  margin-top: -10px;
  float: right;
}

.admin-add-user {
  margin-top: 45vh;
  --background: white;
}

.admin-add-user-icon {
  color: black;
}

.analytics-user {
  margin-top: 2vh;
  --background: white;
}

.admin-analytics-user-icon {
  color: black;
}
.csv-download {
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 85%;
}

.table-margin {
  padding: 3%, 100px, 0, 100px;
}

.side-bar {
  width: 60px;
  background-color: white;
  height: 100vh;
  margin-top: -10px;
  float: right;
}

.add-person {
  font-size: 24px;
  padding-top: 45vh;
  padding-left: 20px;
}

.admin-background {
  --ion-background-color: #d5daef;
}

.admin-create-user-card {
  background: white;
  margin-top: 20%;
}

.admin-input-item {
  --background: rgb(238, 238, 238);
}
